.about {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .65), rgba(0, 0, 0, .65)), url('./bible.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
}

.about > section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 9rem 2rem;
}

.about > section > section > p {
  padding: 1rem 0;
  font-size: 1.6rem;
}

.about > section > h3 {
  text-transform: uppercase;
  text-align: left;
  font-weight: 400;
  font-size: 2rem;
}

.about a {
  color: white;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resoultion: 192dpi) {
  .about {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(./bible@2x.jpg);
  }
}

@media (min-width: 1200px) {
  .about {
    background-position: center bottom;
  }
}
