.player {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 766px;
  margin: auto;
}

.progressBar {
  margin-top: 8.4rem;
  display: flex;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: var(--color-medium-grey);
  color: white;
}

.elapsedTime {
  margin-right: 1.4rem;
}

.duration {
  margin-left: 1.4rem;
}

.track {
  margin: .4rem 0;
  flex: 1;
  border: 1px solid white;
  border-radius: 8px;
  position: relative;
}

.elapsedTrack {
  position: absolute;
  top: 0; left: 0; bottom: 0;
  background-color: white;
  border-radius: 8px;
  z-index: 1;
}

.controlBar {
  background-color: var(--color-medium-grey);
  display: flex;
  color: white;
}

.controlBar button {
  height: 5rem;
  width: 5rem;
  font-size: 0;
  text-align: center;
  background-color: transparent;
  border: none;
  flex: 1;
}

.controlBar button > div {
  width: 3.4rem;
  height: 3.4rem;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  filter: brightness(100);
}

.backSixty {
  background-image: url(./previous2.svg);
}

.backFive {
  background-image: url(./backward2.svg);
  background-position-x: -3px;
}

.play {
  background-image: url(./play3.svg);
}

.pause {
  background-image: url(./pause2.svg);
}

.stop {
  background-image: url(./stop2.svg);
}

.forwardFive {
  background-image: url(./forward3.svg);
}

.forwardSixty {
  background-image: url(./next2.svg);
}
