.topnav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 1rem;
  color: white;
  background-color: rgba(0, 0, 0, .75); 
  height: 5rem;
  overflow: hidden;
  font-size: 1.3rem;
  text-transform: uppercase;
  z-index: 999;
  transition: height 250ms ease-in-out;
}

.topnav a {
  text-decoration: none;
  color: inherit;
}

.hidden {
  height: 0;
}

@media (min-width: 768px) {
  .hidden {
    height: 5rem;
  }
}
