.events {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: var(--color-dark-grey);
  color: white;
}

.events > section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9rem 2rem;
}

.events > section > section {
  padding: 1rem 0;
  font-size: 1.6rem;
}

.events > section > h3 {
  position: relative;
  text-transform: uppercase;
  text-align: left;
  font-weight: 400;
  font-size: 2rem;
  padding: 2rem 0 1rem;
}

.events a {
  color: white;
}

.timeslot {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

.eventsHeader {
  margin-top: 3rem;
}

.eventEntry {
  display: flex;
  margin-bottom: 1rem;
}

.timeBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 10rem;
  min-height: 10rem;
  border: 1px solid white;
  border-radius: 50%;
  margin-right: 2rem;
}

.day {
  text-transform: uppercase;
  font-size: 1.4rem;
}

.date {
  text-transform: uppercase;
  font-size: 2rem;
}

.time {
  text-transform: uppercase;
  font-size: 1.4rem;
}

.descriptionBlock {
  display: flex;
  flex-direction: column;
  min-height: 10rem;
}

.title {
  text-transform: uppercase;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .descriptionBlock {
    margin-left: 3rem;
  }
}
