.mobileMenu {
  font-size: 1.8rem;
}

.mobileMenu a {
  color: var(--color-dark-grey);
}

.mobileMenu ul {
  list-style: none;
  position: fixed;
  top: 1rem; right: -200px;
  width: 200px;
  padding: 5rem 0 2rem;
  background-color: #fff;
  color: var(--color-dark-grey);
  transition: right 250ms ease-in-out;
  border: 1px solid var(--color-medium-grey);
  border-right: 0;
}

.mobileMenu li {
  padding: 1.2rem;
}

.mobileMenu input[type="checkbox"] {
  display: none;
}

.mobileMenu label {
  position: absolute;
  top: 3rem;
  right: 2rem;
  z-index: 100;
}

.bar {
  width: 2.4rem;
  height: .3rem;
  background-color: #fff;
  border-radius: 3px;
  margin: 4px 0;
  transition: all 250ms;
}

.mobileMenu input[type="checkbox"]:checked ~ label > .bar {
  background-color: var(--color-dark-grey);
}

.mobileMenu input[type="checkbox"]:checked ~ label > .bar:nth-last-child(1) {
  transform: rotate(135deg);
  margin-top: -14px;
}

.mobileMenu input[type="checkbox"]:checked ~ label > .bar:nth-last-child(2) {
  opacity: 0;
}

.mobileMenu input[type="checkbox"]:checked ~ label > .bar:nth-last-child(3) {
  transform: rotate(-135deg);
  margin-top: 10px;
}

.mobileMenu input[type="checkbox"]:checked ~ ul {
  right: 0;
}

@media (min-width: 768px) {
  .mobileMenu label {
    height: 0;
    overflow: hidden;
  }
}
