.main {
  display: flex;
  justify-content: center;
  background-color: var(--color-dark-grey);
  height: 100vh;
}

.main > section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  flex: 1;
}

.content {
  margin-top: 6.2rem;
  padding: 2rem 1rem;
  font-size: 2.0rem;
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.passagesRow {
  display: flex;
  justify-content: space-between;
}

.passagesHeader {
  font-size: 1.8rem;
  margin: 2rem 0 1rem;
}

.placeholder {
  width: 4.8rem;
  height: 4.8rem;
}
