.sermonList {
  display: flex;
  justify-content: center;
}

.sermonList > section {
  position: relative;
}

@media (min-width: 768px) {
  body {
    background-color: var(--color-dark-grey);
  }

  .sermonList > section {
    border-right: 1px solid var(--color-medium-grey);
    border-left: 1px solid var(--color-medium-grey);
  }
}

.sermonList header {
  background-color: var(--color-medium-grey);
  color: #fff;
  padding: 1rem 1rem;
  position: fixed;
  margin: auto;
  max-width: 766px;
  top: 0;
  left: 0;
  right: 0;
  height: 8.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.sermonList header > div {
  display: flex;
  flex-direction: column;
}

.brand {
  text-transform: uppercase;
  font-size: 1.4rem;
}

.sermonList h1 {
  font-size: 3.2rem;
  font-weight: normal;
  display: inline-block;
}

.content li {
  color: var(--color-medium-gray);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-medium-grey);
  font-size: 1.6rem;
  padding: 2rem 2rem 2rem 1.5rem;
  position: relative;
}

.actionBox {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.content {
  margin-top: 8.4rem;
  background-color: white;
  height: 100%;
}

.content a {
  color: inherit;
  display: inline-block;
  height: 5.0rem;
  width: 5.0rem;
  flex: none;
  background-image: url(./play3.svg);
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 0;
  margin-right: 1rem;
}

.description {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  flex: 1;
  min-width: 0;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tagline {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.passages, .date {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.editButton {
  z-index: 99;
  width: 4.8rem;
  height: 4.8rem;
  display: inline-block;
}

.editButton > button {
  border: none;
  background-color: transparent;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
}

.editButton > button > svg {
  width: 2.4rem;
  height: 2.4rem;
}

.editButton > button > svg > path {
  fill: rgba(0, 0, 0, .75);
}

.newButton {
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  display: inline-block;
  color: white;
  position: absolute;
  top: 3rem; right: 2rem;
  font-size: 1.8rem;
  cursor: pointer;
}
