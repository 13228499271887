.deleteButton {
  z-index: 99;
  width: 4.8rem;
  height: 4.8rem;
  display: inline-block;
}

.deleteButton > button {
  border: none;
  background-color: transparent;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
}

.deleteButton > button > svg {
  width: 2.4rem;
  height: 2.4rem;
}

.deleteButton > button > svg > path {
  fill: rgba(0, 0, 0, .75);
}
