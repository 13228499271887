.uploadWidget {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: 1px solid var(--color-light-grey);
  padding: 1rem;
  align-items: center;
  font-size: 1.6rem;
}

.uploadWidget > div {
  margin-bottom: 0.8rem;
}
.uploadWidget > div:last-of-type {
  margin-bottom: 0;
}

.uploadWidget:active {
  background-color: var(--color-light-grey);
}

.complete {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.progress {
  align-items: initial;
}
