.directions {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: white;
}

.directions > section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  padding: 5.8rem 2rem;
}

.directions > section > iframe {
  margin-top: 3rem;
  width: 100%;
  min-height: 300px;
}
