.passage {
  font-size: 2.0rem;
  margin-bottom: 1.0rem;
  padding: 1rem;
}

.verseNumber {
  font-size: 1.2rem;
  margin-right: 1.2rem;
}

.passage p {
  margin-bottom: 0.6rem;
}
