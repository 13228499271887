.main {
  display: flex;
  justify-content: center;
  background-color: var(--color-dark-grey);
  height: 100vh;
}

.main > section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  flex: 1;
}

.content {
  margin-top: 6.2rem;
  padding-top: 2rem;
  padding-left: 1rem;
  font-size: 2.0rem;
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.serviceItem {
  border-bottom: 1px solid var(--color-medium-grey);
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem;
}

.mainItem {
  display: flex;
  justify-content: space-between;
}

.subItem {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  font-size: 1.6rem;
}

.timesRow {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.5rem;
}

.timesHeader {
  font-size: 1.6rem;
  margin-top: 2rem;
}

.nameInput {
  max-width: 16rem;
}

.timeInput {
  max-width: 8rem;
}

.placeholder {
  width: 4.8rem;
  height: 4.8rem;
}
