.footer {
  background-color: white;
}

.footer > div {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 3rem 2rem;
  font-size: 1.2rem;
  max-width: 766px;
  margin: auto;
}

.footer a {
  color: inherit;
}
