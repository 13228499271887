* {
  margin: 0;
  padding: 0;
}

*, *::before, *::after {
  box-sizing: inherit;
}

:root {
  --color-dark-grey: #333;
  --color-medium-grey: #555;
  --color-light-grey: #ccc;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  color: var(--color-dark-grey);
}

.content {
  position: relative;
  max-width: 766px;
  flex: 1;
}

.appbar {
  max-width: 766px;
  margin: auto;
  right: auto !important;
}
