.header {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .25), rgba(0, 0, 0, .25)), url(./tbc_front.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.header > section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 6rem;
  text-align: center;
  color: white;
  padding: 3rem 1rem 1rem;
}

.cta {
  border: .2rem solid white;
  color: white;
  background-color: rgba(0, 0, 0, .5);
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem;
  margin-bottom: 8rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resoultion: 192dpi) {
  .header {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .25), rgba(0, 0, 0, .25)), url(./tbc_front@2x.jpg);
  }
}

@media (min-width: 1200px) {
  .header {
    background-position: center bottom;
  }
}
