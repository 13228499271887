.admin {
  display: flex;
  justify-content: center;
  background-color: var(--color-dark-grey);
}

.admin > section {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: white;
}

.admin button {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.6rem; 
}

.admin > section > header {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-medium-grey);
  color: #fff;
  padding: 1rem 1rem;
  font-size: 2.4rem;
}

.admin > section > main {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.admin > section > footer {
  font-size: 1.8rem;
  text-align: center;
  padding: 2rem 1rem;
}

.brand {
  display: flex;
  align-items: center;
}
.avatar {
  border-radius: 50%;
  width: 4.2rem;
  height: 4.2rem;
  margin-right: 1.8rem;
}
