.sermons {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 0 2rem 2rem 2rem;
  background-color: white;
}

.sermons > section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9rem 2rem;
}

.sermons > section > h3 {
  text-transform: uppercase;
  text-align: left;
  font-weight: 400;
  font-size: 2rem;
  padding: 2rem 0 1rem;
}

.sermons a {
  color: var(--color-dark-grey);
  text-decoration: none;
  padding: 1.4rem 0;
}

.blurb {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 3rem;
}

.sermonEntry {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--color-medium-grey);
  font-size: 1.6rem;
}

.sermonEntry:first-of-type {
  border-top: 1px solid var(--color-medium-grey);
}

.date {
  display: block;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-style: italic;
  color: var(--color-medium-grey);
}

.title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.more {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 1rem;
}

.more a {
  text-decoration: underline;
}
