.main {
  display: flex;
  justify-content: center;
  background-color: var(--color-dark-grey);
  height: 100vh;
}

.main > section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  flex: 1;
}

.content {
  margin-top: 6.2rem;
  padding-top: 2rem;
  font-size: 2.0rem;
  background-color: white;
  flex: 1;
}

.itemContainer {
  list-style: none;
}

.serviceItem {
  border-bottom: 1px solid var(--color-medium-grey);
  padding-left: 1rem;
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem;
  list-style: none;
  font-size: 2.0rem;
}

.mainItem {
  display: flex;
  justify-content: space-between;
}

.mainItem > a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

.subItem {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  font-size: 1.6rem;
}
