.privacy {
  position: relative;
  background-color: var(--color-dark-grey);
  color: white;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
 }

 .privacy > section {
  padding: 2rem;
  line-height: 1.6;
}

 .privacy h1, .privacy h2 {
   font-weight: normal;
   margin: 1rem 0;
 }

 .privacy p {
   margin-bottom: 1rem;
 }

 .privacy a {
   color: inherit;
}
