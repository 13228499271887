.main {
  display: flex;
  justify-content: center;
  background-color: var(--color-dark-grey);
  height: 100vh;
}

.main > section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  flex: 1;
}

.content {
  margin-top: 6.2rem;
  padding-top: 2rem;
  background-color: white;
  flex: 1;
}

.serviceItem {
  border-bottom: 1px solid var(--color-medium-grey);
  padding: 0 1rem 1.6rem;
  margin-bottom: 1.6rem;
  list-style: none;
  font-size: 1.8rem;
}

.dateLine {
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  margin-right: -1rem;
}

.dateLine span {
  flex: 1;
}

.mainItem {
  display: flex;
  justify-content: space-between;
  margin-top: -1rem;
}

.mainItem > a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

.subItem {
  padding: 0 2rem 0 0;
  font-size: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
