.sermonPlayer {
  display: flex;
  justify-content: center;
  background-color: var(--color-dark-grey);
  height: 100vh;
}

.sermonPlayer > section {
  display: flex;
  flex-direction: column;
}

.sermonPlayer header {
  background-color: var(--color-medium-grey);
  color: #fff;
  padding: 1rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8.4rem;
  max-width: 766px;
  margin: auto;
}

.brand {
  text-transform: uppercase;
  font-size: 1.4rem;
}

.sermonPlayer h1 {
  font-size: 2.8rem;
  font-weight: normal;
  padding-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 8.4rem;
  margin-top: 8.4rem;
  padding: 0 2rem;
  background: white;
}

.msg {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 2.0rem;
  color: var(--color-medium-grey);
}

@media (min-width: 768px) {
  .content {
    border-right: 1px solid var(--color-medium-grey);
    border-left: 1px solid var(--color-medium-grey);
  }
}

