.editButton {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  z-index: 99;
}

.editButton > button {
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .5);
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
}

.editButton > button > svg > path {
  fill: rgba(255, 255, 255, .75);
}

.editButton.dark > button {
  background-color: rgba(255, 255, 255, .75);
}

.editButton.dark > button > svg > path {
  fill: rgba(0, 0, 0, .5);
}
